<template>
    <div class="section" key="insurance">
        <div class="section-title">
            <div class="author"><img width="50" height="50" src="@/assets/images/rabier.png"></div>
            <h3>Haben Sie eine Rechtsschutzversicherung?</h3>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.insurance" name="radio-1" value="Ja" id="question-3-1">
            <label for="question-3-1" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-security-shield.svg"></span><span>Ja habe ich</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.insurance" name="radio-1" value="Nein" id="question-3-2">
            <label for="question-3-2" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-delete-shield.svg"></span><span>Nein habe ich nicht</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Insurance',
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  }
}
</script>